<div class="row pt-4 main-div" *ngIf="progressbarStatus">
  <div class="col-3 col-sm-2 offset-sm-4 col-md-2 offset-md-4 col-lg-2 col-xl-2 offset-xl-8 offset-lg-8 offset-2">
    <h6 class="pull-right download">Download Status :</h6>
  </div>
  <div class="col-7 col-sm-6 col-md-6 col-lg-2 col-xl-2 px-4">
    <div class="progress progress-new">
      <div class="progress-bar progress-bar-new" role="progressbar" aria-valuenow="0" aria-valuemin="0"
           aria-valuemax="100" [style.width.%]="progressBarValue">
        {{ progressBarValue }}%
      </div>
    </div>
  </div>
</div>
